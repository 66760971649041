import { ChainId, Token } from '@sushiswap/core-sdk'

// export const USDC = new Token(
//   ChainId.SHARDEUMLIBERTY,
//   '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
//   6,
//   'USDC',
//   'USD Coin'
// )
// export const DAI = new Token(
//   ChainId.AVALANCHE,
//   '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70',
//   18,
//   'DAI',
//   'Dai Stablecoin'
// )
// export const USDT = new Token(
//   ChainId.SHARDEUMLIBERTY,
//   '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
//   6,
//   'USDT',
//   'Tether USD'
// )
// export const WBTC = new Token(
//   ChainId.AVALANCHE,
//   '0x50b7545627a5162F82A992c33b87aDc75187B218',
//   8,
//   'WBTC',
//   'Wrapped Bitcoin'
// )
// export const WETH = new Token(
//   ChainId.SHARDEUMLIBERTY,
//   '0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB',
//   18,
//   'WETH',
//   'Wrapped Ether'
// )

export const SWPD = new Token(
  ChainId.SHARDEUMBETANET,
  '0x11F13Ad374E79b466a36eB835747e431fBbE3890',
  18,
  'SWPD',
  'Swapped Finance Token'
)
